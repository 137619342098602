/* App.css */

.App {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
}

.share-bar {
  text-align: right;
}

.share-bar a {
  margin-left: 10px;
}

.faq-section {
  margin-top: 20px;
}

.faq-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.faq-buttons button {
  flex: 1 1 auto;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.faq-buttons button:hover {
  background-color: #0056b3;
}

.chat-history {
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
}

.chat-message {
  margin-bottom: 15px;
}

.chat-message.user .message-content {
  text-align: right;
  background-color: #dcf8c6;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
}

.chat-message.assistant .message-content {
  text-align: left;
  background-color: #f1f0f0;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
}

.chat-input {
  margin-top: 20px;
  display: flex;
}

.chat-input textarea {
  flex: 1;
  resize: none;
  height: 50px;
  padding: 10px;
}

.chat-input button {
  margin-left: 10px;
  padding: 10px 20px;
}

.chat-history {
  max-height: 400px;
  overflow-y: auto;
  padding: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.chat-message.assistant {
  text-align: center;
  margin-top: 16px;
}

.chat-history .ant-list-item {
  padding-left: 16px;
  padding-right: 16px;
}

.chat-message.assistant {
  text-align: center;
}


table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}